import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Box,
  Text,
  Spinner,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  Divider,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import "react-image-lightbox/style.css";
import "./master.css";
import { useAuth, useRedux } from "hooks/redux-hooks";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Moment from "moment";
import { Card } from "components/atoms";
import Lightbox from "react-image-lightbox";
import { FaCheck } from "react-icons/fa";
import { Question } from "models";
import { TimelineStatus } from "components/organisms/TimelineControl";
import { ControlAnswer } from "pages/Maintenance/DoControl";

interface DocReportProps {}

interface IQuestion {
  id: number;
  question: string;
  answer: string;
  note: string;
}

export function questionStatus(question: IQuestion): boolean | null {
  console.log(question);

  if (!!!question.answer) {
    return false;
  }

  if (
    [ControlAnswer.canDevelop, ControlAnswer.cantAgree].includes(
      question.answer as ControlAnswer
    )
  ) {
    return false;
  }
  if (
    [ControlAnswer.notInterest, ControlAnswer.good].includes(
      question.answer as ControlAnswer
    )
  ) {
    return true;
  }

  return null;
}

const configs = {
  dateString: "YYYY-MM-DD[T]HH:mm:ss",
};

const DocReport: FC<DocReportProps & RouteComponentProps> = (props) => {
  const { user, project } = useAuth();
  const [maintenance, setMaintenance] = useState<any>(null);
  const [lightImage, setLightImage] = useState<string | null>(null);

  useEffect(() => {
    const data = localStorage.getItem("doc-report");
    if (!data) {
      props.history.push("/");
    }

    setMaintenance(JSON.parse(data!));
  }, []);

  if (!maintenance) return <Spinner />;

  function getQuestions(): Array<IQuestion[]> {
    var questions: Array<IQuestion[]> = [];
    var tmpQuestions: IQuestion[] = [];
    const questionsSource: any[] = JSON.parse(maintenance.answer);

    var i = 0;
    while (questionsSource.length > i) {
      if (tmpQuestions.length === 7) {
        questions.push(tmpQuestions);
        tmpQuestions = [];
      }
      tmpQuestions.push(questionsSource[i]);
      i++;
    }
    if (tmpQuestions.length !== 0) {
      questions.push(tmpQuestions);
    }

    return questions;
  }

  const questList = getQuestions();

  return (
    <Fragment>
      <div className="book">
        <div className="page">
          <Header />
          <DataTable
            title="Konum Bilgileri"
            data={[
              { title: "Tesis", value: maintenance.campusName },
              { title: "Bina", value: maintenance.buildName },
              { title: "Kat", value: maintenance.floorName },
              { title: "Oda", value: maintenance.roomName },
              { title: "Denetim Adı", value: maintenance.controlName },
              { title: "Periyot", value: maintenance.controlPeriodName },
              {
                title: "Soru Grubu",
                value: maintenance.controlQuestionGroupName,
              },
              { title: "Kullanıcı Tipi", value: maintenance.userTypeName },
              { title: "Açıklama", value: maintenance.controlExplain },
            ]}
          />
          {!!maintenance.inventoryPhoto && (
            <Flex justifyContent="center">
              <img
                alt="Ekipman"
                style={{
                  cursor: "pointer",
                }}
                src={maintenance.inventoryPhoto}
                onClick={(e) => setLightImage(maintenance.inventoryPhoto)}
                width="auto"
                height="150px"
              />
            </Flex>
          )}
          <div className="page-number">1</div>
        </div>
        <div className="page">
          <Header />
          <DataTable
            title="Denetim Bilgileri"
            data={[
              {
                title: "Planlı Denetim Başlangıç Tarihi",
                value: Moment(maintenance.startDate, configs.dateString).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                title: "Planlı Denetim Tarihi",
                value: Moment(maintenance.endDate, configs.dateString).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                title: "Gerçekleşen Denetim Tarihi",
                value: Moment(maintenance.complatedDate).format(
                  "DD/MM/YYYY HH:mm"
                ),
              },
              {
                title: "Gerçekleştiren Kullanıcı",
                value: maintenance.userName,
              },
            ]}
          />
          <div className="page-number">2</div>
        </div>
      </div>
      {questList.map((tenQuestions, indexTQ) => (
        <Page key={`tq-${indexTQ}`} number={indexTQ + 3}>
          <DataTableQuestion
            title="Denetim Soruları"
            data={tenQuestions}
            documents={maintenance.documents}
            setLightImage={setLightImage}
          />
        </Page>
      ))}
      {!!lightImage && (
        <Lightbox
          mainSrc={lightImage!}
          onCloseRequest={() => setLightImage(null)}
        />
      )}
    </Fragment>
  );
};

function Page({ number, children }: any) {
  return (
    <div className="page">
      <Header />
      {children}
      <div className="page-number">{number}</div>
    </div>
  );
}

function Header() {
  const { user, project } = useAuth();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      pb="1"
    >
      <Flex flex="1">
        <Image src={project.companyLogo} width="150px" />
      </Flex>
      <Flex flex="3" justifyContent="center">
        <Text textAlign="center" fontSize="lg">
          Denetim Durum Bilgisi
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        <Text textAlign="center" fontSize="lg">
          {Moment().format("DD.MM.YYYY")}
        </Text>
      </Flex>
    </Flex>
  );
}

function DataTable({ data, title }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      {data && (
        <Table variant="striped" size="sm">
          <Tbody>
            {data.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <span>{item.title}: </span>
                </Td>
                <Td style={{ minWidth: 130 }}>
                  <strong>{item.value}</strong>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

interface IDocumentItem {
  id: number;
  controlTaskMaintenanceDetailID: number;
  extension: string;
  path: string;
  type: string;
  createDate: string;
}

function DataTableQuestion({
  data,
  title,
  documents,
  setLightImage,
}: {
  data: IQuestion[];
  title: string;
  documents: IDocumentItem[];
  setLightImage: any;
}) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      <Flex flexDir="column">
        {data.map((question, index) => (
          <Flex
            p="5"
            py="1"
            key={index}
            borderWidth="1px"
            flexDir="column"
            bgColor={index % 2 === 0 ? "gray.100" : "white"}
          >
            <Flex mb="1" fontSize="sm">
              <Text flex="1">{question.question}: </Text>
              <Text ml="1" fontWeight="bold">
                {question.answer || "Yanıt Yok"} (
                {questionStatus(question) === true
                  ? "Olumlu"
                  : questionStatus(question) === false
                  ? "Olumsuz"
                  : "Değer Girişli"}
                )
              </Text>
            </Flex>
            <Flex borderWidth="1px" p="2" py="2" mb="3">
              <Text fontSize="xs">{question.note || "Not Yok"}</Text>
            </Flex>
            <Box>
              {documents
                ?.filter((item) => {
                  return item.type == question.id.toString();
                })
                .map((item) => {
                  return (
                    <Image
                      src={item.path}
                      height="300px"
                      onClick={() => {
                        setLightImage(item.path);
                      }}
                      cursor="pointer"
                      _hover={{ opacity: ".9" }}
                    />
                  );
                })}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}

function DataCard({ title, children }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md" mb="5">
        {title}
      </Heading>
      <Flex w="100%" flexWrap="wrap" wrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

DocReport.defaultProps = {};

export default DocReport;
