import React, { Fragment, useLayoutEffect } from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  IconButton,
  Icon,
  Spinner,
  Flex,
  Text,
  VStack,
  Box,
  Divider,
  Link,
} from "@chakra-ui/react";
import { IoNotifications } from "react-icons/io5";
import { Hooks } from "services";
import moment from "moment";

function Notifications() {
  const [getN, statN, dataN] = Hooks.NotificationOData();

  useLayoutEffect(() => {
    getN();
  }, []);

  return (
    <Popover placement="bottom-start" autoFocus={false}>
      <PopoverTrigger>
        <IconButton
          mr="2"
          aria-label="notification"
          icon={<Icon as={IoNotifications} />}
          borderRadius="full"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Bildirimler</PopoverHeader>

        <PopoverBody>
          {statN !== "fulfilled" && <Spinner />}
          {statN === "fulfilled" &&
            (dataN as unknown as any[])?.map((item: any) => (
              <Fragment key={item.Id}>
                <Flex py="2">
                  <Text fontSize="sm">{item.title}</Text>
                  <Text ml="3" fontSize="xs">{moment(item.createDate).format("DD.MM.YYYY")}</Text>
                </Flex>
                <Divider />
              </Fragment>
            ))}

            <Flex textAlign="center" my="2" mt="4">
              <Link href="/notice" fontSize="sm" textAlign="center" flex="1">Tümünü Gör</Link>
            </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default Notifications;
