import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { useAuth } from "hooks/redux-hooks";
import { Question } from "models";
import Moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { RouteComponentProps } from "react-router-dom";
import { getApiUrl } from "services/urls";
import "./master.css";
import { getStoreData, Stores } from "helper/indexed-db";
import { Hooks } from "services";

interface DocReportProps {}

const configs = {
  dateString: "YYYY-MM-DD[T]HH:mm:ss",
};

const DocsReport: FC<DocReportProps & RouteComponentProps> = (props) => {
  const [lightImage, setLightImage] = useState<string | null>(null);
  const [data, setData] = useState<any[] | []>([]);
  const [getDocsReports, statDocsReports, dataDocsReports, reset] =
    Hooks.ShowDocsReports();

  useEffect(() => {
    handleGetDocs();
  }, []);
  console.log(dataDocsReports, "bir bakalım ne geliyor");
  function photoUrlManipulation(url: string) {
    if (!url || url === "") return url;
    if (!url.includes("//")) return url;
    url.replace(/\/\//g, "/");
    const apiUrl = getApiUrl();

    url = url.split("/Content")[1];
    url = `${apiUrl}/Content${url}`;
    return url;
  }

  if (!data)
    return (
      <div>
        <Spinner />
      </div>
    );

  async function handleGetDocs() {
    const data = await getStoreData<any>(Stores.Docs);
    setData(data[0].data);
  }

  return (
    <Fragment>
      {data.map((maintenance: any, index: number) => (
        <Fragment key={index}>
          <div className="book">
            <div className="page">
              <Header />
              <DataTable
                title="Ekipman Bilgileri"
                data={[
                  { title: "Tesis", value: maintenance.campusName },
                  { title: "Bina", value: maintenance.buildName },
                  { title: "Kat", value: maintenance.floorName },
                  { title: "Oda", value: maintenance.roomName },
                  { title: "Kod", value: maintenance.barcode },
                  { title: "Envanter No", value: maintenance.inventoryNo },
                  { title: "Ekipman Adı", value: maintenance.name },
                  { title: "Grup", value: maintenance.groupName },
                  { title: "Marka", value: maintenance.brandName },
                  { title: "Model", value: maintenance.modelName },
                  { title: "Seri No", value: maintenance.serialNumber },
                  { title: "Üretim Yılı", value: maintenance.productionYear },
                  {
                    title: "Kapasite Birimi",
                    value: maintenance.inventoryCapacityName,
                  },
                  { title: "Kapasite Değeri", value: maintenance.unit },
                  { title: "Adet", value: maintenance.count },
                ]}
              />
              {!!maintenance.inventoryPhoto && (
                <Flex justifyContent="center">
                  <img
                    alt="Ekipman"
                    style={{
                      cursor: "pointer",
                    }}
                    src={photoUrlManipulation(maintenance.inventoryPhoto)}
                    onClick={(e) =>
                      setLightImage(
                        photoUrlManipulation(maintenance.inventoryPhoto)
                      )
                    }
                    width="auto"
                    height="150px"
                  />
                </Flex>
              )}
              <div className="page-number">1</div>
            </div>
            <div className="page">
              <Header />
              <DataTable
                title="Bakım Bilgileri"
                data={[
                  {
                    title: "Planlı Bakım Tarihi",
                    value: Moment(
                      maintenance.endDate,
                      configs.dateString
                    ).format("DD/MM/YYYY"),
                  },
                  {
                    title: "Gercekleşen Tarih",
                    value: Moment(
                      maintenance.maintenanceDate,
                      configs.dateString
                    ).format("DD/MM/YYYY HH:mm"),
                  },
                  {
                    title: "Bakım Tipi",
                    value: maintenance.maintenanceTypeName,
                  },
                  {
                    title: "Bakım Periyodu",
                    value: maintenance.maintenancePeriodName,
                  },
                  {
                    title: "Kullanıcı",
                    value: maintenance.personelName,
                  },
                  {
                    title: "Bakım Firmasi",
                    value: maintenance.maintenanceFirm,
                  },
                  {
                    title: "Bakım Notları",
                    value: maintenance.maintenanceExplain,
                  },
                  {
                    title: "Bakım Arızaları",
                    value: maintenance.trouble,
                  },
                  {
                    title: "Bakım Eksiklikleri",
                    value: maintenance.problem,
                  },
                ]}
              />

              <DataTable
                title="Form Olayları"
                data={[
                  {
                    title: "ISG Risk Analizi",
                    value: (
                      <Box>
                        <Text>Okudum, Anladım</Text>
                        <Button
                          variant="link"
                          size="xs"
                          onClick={() => {
                            window.open(
                              JSON.parse(maintenance.documentPaths)
                                ?.riskAlalysis,
                              "_new"
                            );
                          }}
                        >
                          Formu Gör
                        </Button>
                      </Box>
                    ),
                    stat: maintenance.riskAnalysisIsRequired,
                  },
                  {
                    title: "Kullanma Klavuzu",
                    value: (
                      <Box>
                        <Text>Okudum, Anladım</Text>
                        <Button
                          variant="link"
                          size="xs"
                          onClick={() => {
                            window.open(
                              JSON.parse(maintenance.documentPaths)?.userGuide,
                              "_new"
                            );
                          }}
                        >
                          Formu Gör
                        </Button>
                      </Box>
                    ),
                    stat: maintenance.userGudiePathIsRequired,
                  },
                  {
                    title: "Kullanıcı Talimatları",
                    value: (
                      <Box>
                        <Text>Okudum, Anladım</Text>
                        <Button
                          fontSize="xs"
                          variant="link"
                          onClick={() => {
                            window.open(
                              JSON.parse(maintenance.documentPaths)
                                ?.userInstruction,
                              "_new"
                            );
                          }}
                        >
                          Formu Gör
                        </Button>
                      </Box>
                    ),
                    stat: maintenance.userInstructionsIsRequired,
                  },
                  {
                    title: "Bakım Sözleşmesi",
                    value: (
                      <Box>
                        <Text>Okudum, Anladım</Text>
                        <Button
                          variant="link"
                          size="xs"
                          onClick={() => {
                            window.open(
                              JSON.parse(maintenance.documentPaths)
                                ?.maintenanceContract,
                              "_new"
                            );
                          }}
                        >
                          Formu Gör
                        </Button>
                      </Box>
                    ),
                    stat: maintenance.maintenanceContractIsRequired,
                  },
                ].filter((item) => item.stat)}
              />
              <div className="page-number">2</div>
            </div>
            <div className="page">
              <Header />
              <DataTable
                title="Bakım Soruları"
                data={JSON.parse(maintenance.maintenanceQuestion).map(
                  (question: any) => {
                    return {
                      title: question.Question,
                      value:
                        question.SelectedItem == null
                          ? "Cevap Girilmemiş"
                          : question.SelectedItem === "Yes"
                          ? `Evet (${
                              question.trueFalseAnswer === true ||
                              question.trueFalseAnswer === undefined
                                ? "Olumlu"
                                : "Olumsuz"
                            })`
                          : question.SelectedItem === "No"
                          ? `Hayır (${
                              question.trueFalseAnswer === true ||
                              question.trueFalseAnswer === undefined
                                ? "Olumsuz"
                                : "Olumlu"
                            })`
                          : !!question.InitialValue
                          ? question.InitialValue === "-2560" &&
                            question.EndValue === "-2560"
                            ? question.SelectedItem
                            : `${question.SelectedItem} (${
                                parseInt(question.SelectedItem) >=
                                  parseInt(question.InitialValue) &&
                                parseInt(question.SelectedItem) <=
                                  parseInt(question.EndValue)
                                  ? "Olumlu Değer Aralığında"
                                  : "Olumlu Değer Aralığı Dışında"
                              })`
                          : `${question.SelectedItem} (${
                              question.SelectedItem >= question.InitialValue &&
                              question.SelectedItem <= question.EndValue
                                ? "Olumlu Değer Aralığında"
                                : "Olumlu Değer Aralığı Dışında"
                            })`,
                    };
                  }
                )}
              />
              <div className="page-number">3</div>
            </div>

            {maintenance &&
              maintenance.photos
                .filter((photo: any) => photo.type === "formPhoto")
                .map((photo: any, index: number) => (
                  <div className="page">
                    <Header />
                    <DataCard title="İş İzin / Bakım Formu">
                      <img
                        alt={"Form" + index}
                        style={{
                          cursor: "pointer",
                          maxHeight: "100%",
                          maxWidth: "100%",
                          marginRight: 3,
                        }}
                        src={photoUrlManipulation(photo.photoPath)}
                        onClick={(e) =>
                          setLightImage(photoUrlManipulation(photo.photoPath))
                        }
                        width="auto"
                        height="100%"
                      />
                    </DataCard>
                    <div className="page-number">4</div>
                  </div>
                ))}

            <div className="page">
              <Header />
              <DataCard title="Ekipman Fotoğrafları">
                {maintenance &&
                  maintenance.photos
                    .filter((photo: any) => photo.type === "inventoryPhoto")
                    .map((photo: any, index: number) => (
                      <Flex mb="2" justifyContent="center" key={index}>
                        <img
                          alt={"Form" + index}
                          style={{
                            cursor: "pointer",
                            maxHeight: "230px",
                            maxWidth: "100%",
                            marginRight: 3,
                          }}
                          src={photoUrlManipulation(photo.photoPath)}
                          onClick={(e) =>
                            setLightImage(photoUrlManipulation(photo.photoPath))
                          }
                          width="auto"
                          height="auto"
                        />
                      </Flex>
                    ))}
              </DataCard>
              <div className="page-number">
                {maintenance &&
                  maintenance.photos.filter(
                    (photo: any) => photo.type === "formPhoto"
                  ).length + 4}
              </div>
            </div>
          </div>
          {!!lightImage && (
            <Lightbox
              mainSrc={lightImage!}
              onCloseRequest={() => setLightImage(null)}
            />
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

function Header() {
  const { user, project } = useAuth();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      pb="1"
    >
      <Flex flex="1">
        <Image src="/otk-logo.svg" height="30px" />
      </Flex>
      <Flex flex="3" justifyContent="center">
        <Text textAlign="center" fontSize="lg">
          Ekipman Bakım Durum Bilgisi
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        <Text textAlign="center" fontSize="lg">
          {Moment().format("DD.MM.YYYY")}
        </Text>
      </Flex>
    </Flex>
  );
}

function DataTable({ data, title }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      {data && (
        <Table variant="striped" size="sm">
          <Tbody>
            {data.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <span>{item.title}: </span>
                </Td>
                <Td style={{ minWidth: 130 }}>
                  <strong>{item.value}</strong>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

function DataCard({ title, children }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md" mb="5">
        {title}
      </Heading>
      <Flex w="100%" flexWrap="wrap" wrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

DocsReport.defaultProps = {};

export default DocsReport;
