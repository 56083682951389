import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useGetUserOnStore } from "hooks/find-users-on-store";
import { useAuth } from "hooks/redux-hooks";
import moment from "moment";
import { FC, useLayoutEffect, useState } from "react";
import { Hooks } from "services";
import DailyNoteForm from "../daily-note-form/DailyNoteForm";
import DailyNoteItem from "./DailyNoteItem";

type DailyNoteListProps = {};

const DailyNoteList: FC<DailyNoteListProps> = (props) => {
  const disclosure = useDisclosure();
  const { getUser } = useGetUserOnStore();
  const { user: sessionUser } = useAuth();
  const [getNotes, statusNotes, dataNotes] = Hooks.NoteODataWithParams();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedUser, setSelectedUser] = useState("all");

  function getUsers() {
    const users: any[] = [];
    (dataNotes?.data as any)?.forEach((note: any) => {
      const user = getUser(note.userID);
      const userFullname = getUserFullname(user);

      if (!users.includes(userFullname)) {
        users.push(userFullname);
      }
    });
    return users;
  }

  function userTypeFilter(x: any) {
    console.log("User Type Filter", x.title)
    if (x.title === "Title") {
      return true;
    }

    let noteTypesIds: number[] = [];
    try {
      noteTypesIds = JSON.parse(x.title) as number[];
    } catch (error) {
      return true;
    }

    let hasSessionUserNoteType = sessionUser.userTypes.some((userType: any) => {
      console.log(noteTypesIds, userType);
      return noteTypesIds.includes(userType.id);
    });

    console.log(hasSessionUserNoteType);

    return hasSessionUserNoteType;
  }

  useLayoutEffect(() => {
    getNotes(date);
  }, [date]);

  const getUserFullname = (user?: IUser | undefined | null) => {
    if (!user) return "";

    return `${user.name} ${user.surname}`;
  };

  return (
    <Box pb="20">
      <HStack justifyContent="space-between" bgColor="gray.50" px="3" my="2">
        <Heading size="md" pb="6" mt="6">
          {moment(date, "YYYY-MM-DD").format("DD MMMM YYYY dddd")} Notları
        </Heading>
        <HStack>
          <HStack>
            <Input
              size="sm"
              ml="2"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </HStack>
          <Select
            size="sm"
            value={selectedUser}
            onChange={(e) => {
              setSelectedUser(e.target.value);
            }}
          >
            <option value="all">Tümü</option>
            {getUsers().map((user) => (
              <option value={user}>{user}</option>
            ))}
          </Select>
          <Divider orientation="vertical" h="20px" borderColor="gray.500" />
          <HStack>
            <Button colorScheme="blue" size="sm" onClick={disclosure.onToggle}>
              Ekle
            </Button>
          </HStack>
        </HStack>
      </HStack>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Not Ekle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DailyNoteForm
              date={date}
              onCreate={() => {
                disclosure.onClose();
                getNotes(date);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {statusNotes !== "fulfilled" && <Spinner />}
      {statusNotes === "fulfilled" &&
        dataNotes &&
        (dataNotes?.data as any)
          .filter(userTypeFilter)
          .filter((item: any) => {
            if (selectedUser === "all") return true;
            const _user = getUserFullname(getUser(item.userID));
            console.log({ _user, selectedUser });
            return selectedUser === _user;
          })
          .map((item: any, index: number) => (
            <DailyNoteItem index={index} item={item} />
          ))}
    </Box>
  );
};

export default DailyNoteList;
