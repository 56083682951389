import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useAuth } from "hooks/redux-hooks";
import React, { Fragment, useLayoutEffect } from "react";
import { Switch } from "react-router-dom";
import { Hooks } from "services";
import { routes, authRoutes } from "./routes";

export const AuthRouter = () => (
  <GoogleReCaptchaProvider reCaptchaKey="6Ldc_jopAAAAAMlrpFhn03o12ONl8NNY7Wadl-uU">
  <Switch>
    {authRoutes.map((_route, _index) => (
      <_route.route {..._route} key={_index} />
    ))}
  </Switch>
  </GoogleReCaptchaProvider>
  
);

export default () => {
  const [getUsers] = Hooks.UsersStore();
  const { project } = useAuth();

  useLayoutEffect(() => {
    getUsers(project.id);
  }, []);
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldc_jopAAAAAMlrpFhn03o12ONl8NNY7Wadl-uU">
      <Switch>
        {routes.map((_route, _index) => (
          <_route.route {..._route} key={_index} />
        ))}
      </Switch>
    </GoogleReCaptchaProvider>
  );
};
