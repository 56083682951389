import {
  Badge,
  Box,
  Center,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FeatureEnum, getFeatures } from "consts/features";
import { isSieOrNo } from "helper/project-mode";
import { useAuth } from "hooks/redux-hooks";
import { useResponsive } from "hooks/responsive";
import { UserRoles } from "models/user";
import { ReactNodeArray } from "react";
import {
  AiOutlineBarChart,
  BsBookmarkPlus,
  BsCardChecklist,
  BsPeople,
  GoRequestChanges,
  HiArchive,
  HiClipboardList,
  HiDocumentDuplicate,
  HiTag,
  VscTools,
} from "react-icons/all";
import {
  IoAnalytics,
  IoBarChart,
  IoChevronDown,
  IoDocument,
  IoDocumentTextOutline,
  IoHardwareChip,
  IoList,
  IoLocation,
  IoNotifications,
  IoPencil,
  IoPeople,
  IoTimer,
} from "react-icons/io5";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link, useHistory } from "react-router-dom";
import { mainPaths } from "router/path";
import { api, __mode__ } from "../../consts";

function Item({ icon, title, path, mt, full, isBeta }: any) {
  const history = useHistory();
  return (
    <HStack
      onClick={() => history.push(path)}
      alignItems="center"
      cursor="pointer"
      bgColor={"gray.800"}
      w={full ? "100%" : "85%"}
      px="3"
      mt={mt}
      py="2"
      borderRadius="md"
      _hover={{ bgColor: "gray.700" }}
    >
      <Center bgColor="black" borderRadius="full" h="30px" w="30px">
        <Icon as={icon} color="white" />
      </Center>
      <Text fontSize="small" color="gray.200">
        {title}
      </Text>
      {isBeta && (
        <Badge fontSize="xs" colorScheme="red">
          Beta
        </Badge>
      )}
    </HStack>
  );
}

function CollapseItem({ mt, icon, title, children }: any) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Flex
        onClick={onToggle}
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        bgColor={"gray.800"}
        w="85%"
        px="3"
        mt={mt}
        py="2"
        borderRadius="md"
        _hover={{ bgColor: "gray.700" }}
      >
        <Flex alignItems="center">
          <Center bgColor="black" borderRadius="full" h="30px" w="30px">
            <Icon as={icon} color="white" />
          </Center>
          <Text fontSize="small" ml="2" color="gray.200">
            {title}
          </Text>
        </Flex>
        <Center
          rounded="full"
          bgColor="black"
          transition="linear"
          transitionDuration="100ms"
          w="25px"
          h="25px"
          __css={{ transform: `rotate(${isOpen ? "0" : "-90"}deg)` }}
        >
          <Icon as={IoChevronDown} mt="1px" />
        </Center>
      </Flex>
      <Box w="85%" pl="3">
        <Collapse in={isOpen} animateOpacity>
          <Box>{children}</Box>
        </Collapse>
      </Box>
    </>
  );
}

function Sidebar({ drawerDisc }: any) {
  const { isSm, isBase } = useResponsive();
  const { role, user, project } = useAuth();

  const renderFeatures = () => {
    const features = getFeatures();

    const featuresRow: ReactNodeArray = [];

    features.forEach((feat) => {
      switch (feat) {
        case FeatureEnum.maintenance:
          featuresRow.push(
            <CollapseItem
              title="Ekipman İşlemleri"
              icon={HiArchive}
              mt="3"
              key={feat}
            >
              <Item
                icon={VscTools}
                mt="2"
                title="Ekipman Listesi"
                path="/inventory"
                full
              />
              <Item
                icon={VscTools}
                mt="2"
                title="Ekipman Kontrol Listesi"
                path="/maintenance/all"
                full
              />
              <Item
                icon={AiOutlineBarChart}
                mt="2"
                title="Eksik Ekipman Dosyaları"
                path="/maintenance-non"
                full
              />
              <Item
                icon={IoHardwareChip}
                mt="2"
                title="Bakım Raporları"
                path="/report/completed"
                full
              />
              {/* <Item
                icon={IoHardwareChip}
                mt="2"
                title="İç Bakım Raporları"
                path="/report/inside-completed"
                full
              />
              <Item
                icon={IoHardwareChip}
                mt="2"
                title="Dış Bakım Raporları"
                path="/report/outside-completed"
                full
              /> */}
              <Item
                icon={IoHardwareChip}
                mt="2"
                title={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Raporları`}
                path="/report/completed-period"
                full
              />
            </CollapseItem>
          );
          break;
        case FeatureEnum.follow:
          featuresRow.push(
            <CollapseItem
              key={feat}
              title="Belge Takip İşlemler"
              icon={HiDocumentDuplicate}
              mt="3"
            >
              <Item
                icon={IoDocumentTextOutline}
                mt="2"
                title="Belge Takip Listesi"
                path="/document"
                full
              />
              <Item
                icon={IoDocumentTextOutline}
                mt="2"
                title="Belge Kontrol Listesi"
                path="/all-document"
                full
              />
              <Item
                icon={IoHardwareChip}
                mt="2"
                title="Belge Takip Raporları"
                path="/report-document-completed"
                full
              />
            </CollapseItem>
          );
          break;
        case FeatureEnum.demand:
          featuresRow.push(
            <CollapseItem
              key={feat}
              title="Talep İşlemleri"
              icon={HiTag}
              mt="3"
            >
              <Item
                icon={GoRequestChanges}
                mt="2"
                title="Talep Listesi"
                path="/demand"
                full
              />
              <Item
                icon={GoRequestChanges}
                mt="2"
                title="Talep Doküman Listesi"
                path="/all-demand-document"
                full
              />
              <Item
                icon={IoHardwareChip}
                mt="2"
                title="Talep Raporları"
                path="/report/completed-demand"
                full
              />
            </CollapseItem>
          );
          break;
        case FeatureEnum.legal:
          featuresRow.push();
          break;
        case FeatureEnum.document:
          featuresRow.push(
            <CollapseItem
              key={feat}
              title="Denetim İşlemleri"
              icon={HiClipboardList}
              mt="3"
            >
              <Item
                icon={BsCardChecklist}
                mt="2"
                title="Denetim Listesi"
                path="/control"
                full
              />
              <Item
                icon={BsCardChecklist}
                mt="2"
                title="Denetim Görev Listesi"
                path="/all-task"
                full
              />
              <Item
                icon={IoHardwareChip}
                mt="2"
                title="Denetim Soru Formları"
                path="/control-question-form"
                full
              />
              <Item
                icon={IoHardwareChip}
                mt="2"
                title="Denetim Raporları"
                path="/report-control-task-completed"
                full
              />
            </CollapseItem>
          );
          break;
        case FeatureEnum.counter:
          featuresRow.push(
            <CollapseItem
              key={feat}
              title="Sayaç İşlemleri"
              icon={IoTimer}
              mt="3"
            >
              <Item
                icon={IoList}
                mt="2"
                title="Sayaç Listesi"
                path="/counter"
                full
              />
            </CollapseItem>
          );
          break;
        default:
          break;
      }
    });

    return featuresRow;
  };

  const body = (
    <Box color="white" w="300px" bgColor="gray.900" userSelect="none">
      <PerfectScrollbar style={{ height: "calc(100vh - 80px)" }}>
        <Flex flexDir="column" alignItems="center">
          <Link to="/">
            {__mode__ === api.otk ? (
              <Image
                filter="invert(100%)"
                src="/otk-logo.png"
                w="90%"
                maxW="200px"
                margin="auto"
                h="auto"
                mt="10"
              />
            ) : __mode__ === api.apl ? (
              <Image
                src={project.companyLogo}
                w="90%"
                maxW="200px"
                margin="auto"
                h="auto"
                mt="10"
              />
            ) : (
              <Image
                src={project.companyLogo}
                w="auto"
                h="45px"
                m="auto"
                mt="10"
              />
            )}
          </Link>
          {__mode__ === api.test && <Badge colorScheme="red">Test</Badge>}
          <Item icon={IoAnalytics} mt="10" title="Kontrol Merkezi" path="/" />
          {/* <Item
            icon={IoAnalytics}
            mt="10"
            title="Bakım Çizelgesi"
            path="/timeline"
            isBeta
          /> */}
          {/* <Item
            icon={IoBarChart}
            mt="3"
            title="İşlem Detayları"
            path={mainPaths.ProcessDetail}
          /> */}
          {renderFeatures()}

          {role !== UserRoles.basic && (
            <CollapseItem title="Tanımlamalar" icon={BsBookmarkPlus} mt="3">
              <Item
                icon={IoLocation}
                mt="2"
                title="Tesisler"
                path="/campus"
                full
              />
              <Item
                icon={IoPeople}
                mt="2"
                title="Kullanıcılar"
                path="/user"
                full
              />
              {role === UserRoles.admin && (
                <Item
                  icon={BsPeople}
                  mt="2"
                  title="Kullanıcı Tipleri"
                  path="/user-type"
                  full
                />
              )}
            </CollapseItem>
          )}
          <CollapseItem title="Notlar" icon={IoPencil} mt="3">
            <Item
              icon={IoPencil}
              mt="3"
              title="Günlük Notlar"
              path="/note"
              full
            />
            <Item
              icon={IoPencil}
              mt="3"
              title="Tüm Notlar"
              path="/all-note"
              full
            />
            <Item
              icon={IoPencil}
              mt="3"
              title="Not Takibi"
              path="/note-tracer"
              full
            />
          </CollapseItem>
          <Item
            icon={IoDocument}
            mt="3"
            title="Formlar"
            path="/form-files"
          />
          {role !== UserRoles.basic && (
            <Item
              icon={IoNotifications}
              mt="3"
              title="Bildirimler"
              path="/notice"
            />
          )}
        </Flex>
      </PerfectScrollbar>
      <Box borderTopWidth="1px" mt="1">
        <Flex
          p="3"
          rounded="sm"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Text fontSize="xs">{project.name}</Text>
            <Text fontSize="sm">
              {user.name} {user.surname}
            </Text>
          </Box>
          <Badge colorScheme="teal" size="xs" fontSize="xs">
            {user.userRoleID === 1
              ? "Admin"
              : user.userRoleID === 2
              ? "Yönetici"
              : "Standart"}
          </Badge>
        </Flex>
      </Box>
    </Box>
  );

  if (isBase || isSm) {
    return (
      <Drawer
        isOpen={drawerDisc.isOpen}
        placement="left"
        onClose={drawerDisc.onClose}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="black">
          <DrawerCloseButton
            color="white"
            outline="none"
            border="none"
            boxShadow="none"
            zIndex="modal"
          />
          <DrawerBody p="0">{body}</DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  return body;
}

export default Sidebar;
