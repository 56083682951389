export enum UserRoles {
  admin = 1,
  manager = 2,
  basic = 3,
  view = 4
}

export enum UserRolesName {
  admin = "Admin",
  manager = "Yönetici",
  basic = "Standart",
  view = "Görüntüle"
}

export default {} 