import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { Card, DataTable } from "components/atoms";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { IoFilter } from "react-icons/io5";
import { isSieOrNo } from "helper/project-mode";

interface MaintenanceAllListProps {}

const MaintenanceAllList: FC<MaintenanceAllListProps> = (props) => {
  const { project } = useAuth();
  const [getAll, statusAll, dataAll] = Hooks.AllMaintenanceList();
  const [getCampus, stat, dataCampus] = Hooks.CampusList();
  const [maintenanceType, setMaintenanceType] = useState<string>("0");
  const [maintenanceCampus, setCampus] = useState<string>("0");

  useLayoutEffect(() => {
    getAll(project.id);
    getCampus(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Ekipman Kontrol Listesi" }}>
      {statusAll !== "fulfilled" && (
        <Center h="100px">
          <Spinner />
        </Center>
      )}
      {statusAll === "fulfilled" && (
        <>
          <Card mb="5">
            <Flex justifyContent="flex-start">
              <Select
                size="sm"
                maxW="300px"
                value={maintenanceType}
                onChange={(e) => {
                  setMaintenanceType(e.target.value);
                }}
              >
                <option value="0">Bakım Tipi Seçiniz</option>
                <option value="İç Bakım / Kontrol">İç Bakım / Kontrol</option>
                <option value="DIş Bakım">Dış Bakım</option>
                <option value={`Periyodik ${isSieOrNo("Kontrol", "Muayene")}`}>
                  Periyodik {isSieOrNo("Kontrol", "Muayene")}
                </option>
              </Select>
              <Select
                ml="2"
                size="sm"
                maxW="300px"
                value={maintenanceCampus}
                onChange={(e) => {
                  setCampus(e.target.value);
                }}
              >
                <option value="0">Tesis Seçiniz</option>
                {dataCampus?.data.data.map((campus) => (
                  <option key={campus.id} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </Card>
          <Card>
            <DataTable
              data={dataAll?.data.data
                .filter((item: any) =>
                  maintenanceCampus === "0"
                    ? true
                    : item.campusId.toString() === maintenanceCampus
                )
                .filter((item: any) => {
                  return maintenanceType === "0"
                    ? true
                    : maintenanceType.toLowerCase() ===
                        item.maintenanceTypeName.toLowerCase();
                })}
              columns={[
                {
                  name: "Barkod",
                  selector: "barcode",
                },
                {
                  name: "Tesis",
                  selector: "campusName",
                },
                {
                  name: "Ekipman Adı",
                  selector: "inventoryName",
                },
                {
                  name: "Bakım Tipi",
                  selector: "maintenanceTypeName",
                },
                {
                  name: "Bakım Periyodu",
                  selector: "maintenancePeriodName",
                },
                {
                  name: "Oluşturulma Tarihi",
                  cell: (row) => Moment(row.startDate).format("DD.MM.YYYY"),
                  selector: "startDate",
                  columnType: "date",
                },
              ]}
            />
          </Card>
        </>
      )}
    </Main>
  );
};

MaintenanceAllList.defaultProps = {};

export default MaintenanceAllList;
