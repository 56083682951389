import React, { FC, useEffect, useLayoutEffect, useState, memo } from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  Button,
  Input,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import RSelect from "react-select";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { IoFilter } from "react-icons/io5";
import moment from "moment";

interface InventoryODataFilterProps {
  onFilter: (data: any) => void;
  noGroups?: boolean;
}

const FilterSelect = memo((props: any) => {
  return (
    <FormControl>
      <FormLabel fontSize="sm">{props.label}</FormLabel>
      <RSelect
        isClearable
        isSearchable
        value={props.value}
        onChange={(value) => {
          props.onChange(value);
        }}
        isLoading={props.status === "pending"}
        placeholder="Seç"
        isDisabled={!props.data}
        options={props.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))}
      />
    </FormControl>
  );
});

const InventoryODataFilter: FC<InventoryODataFilterProps> = (props) => {
  const { project, user } = useAuth();
  const [getCampus, statCampus, dataCampus] = Hooks.CampusList();
  const [getBuild, statBuild, dataBuild, resetBuild] = Hooks.BuildList();
  const [getFloor, statFloor, dataFloor, resetFloor] = Hooks.FloorList();
  const [getRoom, statRoom, dataRoom, resetRoom] = Hooks.RoomList();
  const [getUserType, statUT, dataUT] = Hooks.UserTypeList();
  const [getGroup, statGroup, dataGroup] = Hooks.GroupList();
  const [getBrand, statBrand, dataBrand, resetBrand] = Hooks.BrandList();
  const [getModel, statModel, dataModel, resetModel] = Hooks.ModelList();
  const [getUser, statUser, dataUser] = Hooks.UserList();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();

  const [filterData, setFilterData] = useState<any>({});
  const [mount, setMount] = useState(false);

  useEffect(() => {
    const _filterData = localStorage.getItem("filter-odata");
    if (_filterData !== null) {
      let tmpFilterData = JSON.parse(_filterData);
      setFilterData(tmpFilterData);
      //props.onFilter(tmpFilterData);
    }
  }, []);

  useLayoutEffect(() => {
    getCampus(project.id);
    getUserType();
    getGroup(project.id);
    getUser(project.id);
    getPeriods(project.id);
  }, []);

  useEffect(() => {
    if (!!filterData?.campusName) {
      if (mount) {
        setFilterData((state: any) => ({ ...state, buildName: undefined }));
      }
      getBuild(filterData.campusName.value);
    } else if (!filterData.campusName && !!dataBuild) {
      resetBuild();
      setFilterData((state: any) => ({
        ...state,
        campusName: undefined,
        buildName: undefined,
      }));
    }
  }, [filterData?.campusName]);

  useEffect(() => {
    if (!!filterData?.buildName) {
      if (mount) {
        setFilterData((state: any) => ({ ...state, floorName: undefined }));
      }
      getFloor(filterData.buildName.value);
    } else if (!filterData?.buildName && !!dataFloor) {
      resetFloor();
      setFilterData((state: any) => ({ ...state, floorName: undefined }));
    }
  }, [filterData?.buildName]);

  useEffect(() => {
    if (!!filterData?.floorName) {
      if (mount) {
        setFilterData((state: any) => ({ ...state, roomName: undefined }));
      }
      getRoom(filterData.floorName.value);
    } else if (!filterData?.floorName && !!dataRoom) {
      resetRoom();
      setFilterData((state: any) => ({ ...state, roomName: undefined }));
    }
  }, [filterData?.floorName]);

  useEffect(() => {
    if (!!filterData?.groupName) {
      if (mount) {
        setFilterData((state: any) => ({ ...state, brandName: undefined }));
      }
      getBrand(filterData.groupName.value);
    } else if (!filterData.groupName && !!dataBrand) {
      resetBrand();
      setFilterData((state: any) => ({
        ...state,
        groupName: undefined,
        brandName: undefined,
      }));
    }
  }, [filterData?.groupName]);

  useEffect(() => {
    if (!!filterData?.brandName) {
      if (mount) {
        setFilterData((state: any) => ({ ...state, modelName: undefined }));
      }
      getModel(filterData.brandName.value);
    } else if (!filterData.brandName && !!dataModel) {
      resetModel();
      setFilterData((state: any) => ({ ...state, modelName: undefined }));
    }
  }, [filterData?.brandName]);

  return (
    <Card mb="3">
      <Flex>
        <IoFilter />
        <Text fontWeight="bold" fontSize="xs" ml="2">
          Filtre
        </Text>
      </Flex>
      <SimpleGrid columns={[5]} mt="3" gap="3">
        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, campusName: value }));
          }}
          value={filterData.campusName || null}
          data={dataCampus?.data?.data}
          status={statCampus}
          label="Tesis"
        />

        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, buildName: value }));
          }}
          value={filterData.buildName || null}
          data={dataBuild?.data?.data}
          status={statBuild}
          label="Bina"
        />
        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, floorName: value }));
          }}
          value={filterData.floorName || null}
          data={dataFloor?.data?.data}
          status={statFloor}
          label="Kat"
        />
        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, roomName: value }));
          }}
          value={filterData.roomName || null}
          data={dataRoom?.data?.data}
          status={statRoom}
          label="Oda"
        />
        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, userTypeName: value }));
          }}
          value={filterData.userTypeName || null}
          data={dataUT?.data?.data.filter((_item: any) =>
            user.userTypes.map((_: any) => _.id).includes(_item.id)
          )}
          status={statUT}
          label="Kullanıcı Tipi"
        />
        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({ ...state, personelName: value }));
          }}
          value={filterData.personelName || null}
          data={dataUser?.data?.data.map((user) => ({
            id: user.id,
            name: `${(user as any).name} ${(user as any).surname}`,
          }))}
          status={statUT}
          label="Kullanıcı"
        />
        <FilterSelect
          onOpen={() => setMount(true)}
          onChange={(value: any) => {
            setFilterData((state: any) => ({
              ...state,
              maintenancePeriodName: value,
            }));
          }}
          value={filterData.maintenancePeriodName || null}
          data={dataPeriods?.data.data}
          status={statPeriods}
          label="Periyot"
        />

        {!!!props.noGroups && (
          <>
            <FilterSelect
              onOpen={() => setMount(true)}
              onChange={(value: any) => {
                setFilterData((state: any) => ({ ...state, groupName: value }));
              }}
              value={filterData.groupName || null}
              data={dataGroup?.data?.data}
              status={statGroup}
              label="Grup"
            />
            <FilterSelect
              onOpen={() => setMount(true)}
              onChange={(value: any) => {
                setFilterData((state: any) => ({ ...state, brandName: value }));
              }}
              value={filterData.brandName || null}
              data={dataBrand?.data?.data}
              status={statBrand}
              label="Marka"
            />
            <FilterSelect
              onOpen={() => setMount(true)}
              onChange={(value: any) => {
                setFilterData((state: any) => ({ ...state, modelName: value }));
              }}
              value={filterData.modelName || null}
              data={dataModel?.data?.data}
              status={statModel}
              label="Model"
            />
          </>
        )}
      </SimpleGrid>
      <SimpleGrid spacing="4" columns={5} mt="5">
        <FormControl>
          <FormLabel>Başlangıç Tarihi</FormLabel>
          <Input
            placeholder="Başlangıç Tarihi"
            type="date"
            max={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              setFilterData((prev: any) => ({
                ...prev,
                startDate: e.target.value,
              }))
            }
            value={filterData.startDate}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Bitiş Tarihi</FormLabel>
          <Input
            placeholder="Bitiş Tarihi"
            type="date"
            max={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              setFilterData((prev: any) => ({
                ...prev,
                endDate: e.target.value,
              }))
            }
            value={filterData.endDate}
          />
        </FormControl>
      </SimpleGrid>
      <Flex mt="3" justifyContent="flex-end">
        <Button
          mr="2"
          size="sm"
          colorScheme="red"
          onClick={() => {
            setFilterData({});
            localStorage.removeItem("filter-odata");
            props.onFilter({});
          }}
        >
          Sıfırla
        </Button>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={() => {
            localStorage.setItem("filter-odata", JSON.stringify(filterData));
            props.onFilter(filterData);
          }}
        >
          Filtrele
        </Button>
      </Flex>
    </Card>
  );
};

InventoryODataFilter.defaultProps = {};

export default InventoryODataFilter;
