/* eslint-disable react/jsx-no-target-blank */
import { Button, Flex, Select, Text } from "@chakra-ui/react";
import { Card, DataTable } from "components/atoms";
import Main from "components/templates/Main";
import { useGetUserOnStore } from "hooks/find-users-on-store";
import { useAuth } from "hooks/redux-hooks";
import moment from "moment";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import * as R from "remeda";
import { Hooks } from "services";
import { getApiUrl } from "services/urls";

type ListProps = {};

const List: FC<ListProps> = (props) => {
  const [getFiles, statusFiles, dataFiles] = Hooks.GetFileFormsByUserType();
  const [getCampus, stat, dataCampus] = Hooks.CampusList();
  const { getUser } = useGetUserOnStore();
  const { user, project } = useAuth();
  const [maintenanceCampus, setCampus] = useState<string>("0");

  useLayoutEffect(() => {
    getCampus(project.id);
  }, []);

  useEffect(() => {
    getFiles(null);
  }, [maintenanceCampus]);

  function filterDataByCampus(item: any) {
    if (maintenanceCampus !== "0") {
      return item.tmp3 === maintenanceCampus;
    }

    const formType = R.pipe(
      user.userTypes,
      R.find((userType: any) => userType.name === item.tmp3)
    );

    return Boolean(formType);
  }

  return (
    <Main headerProps={{ title: "Formlar" }}>
      <Card>
        <Select
          ml="2"
          size="sm"
          maxW="300px"
          value={maintenanceCampus}
          onChange={(e) => {
            setCampus(e.target.value);
          }}
        >
          <option value="0">Tesis Seçiniz</option>
          {dataCampus?.data.data.map((campus) => (
            <option key={campus.id} value={campus.name ?? campus.id}>
              {campus.name}
            </option>
          ))}
        </Select>
      </Card>
      <Card>
        <DataTable
          columns={[
            { name: "ID", selector: "id" },
            {
              name: "Başlık",
              selector: "title",
            },
            {
              name: "Tarih",
              cell: (row) => moment(row.date).format("DD/MM/YYYY HH:mm"),
            },
            {
              name: "Tesis",
              selector: "tmp3",
            },
            {
              name: "Kullanıcı",
              cell: (row) => {
                const user = getUser(row.userId);

                if (!Boolean(user)) return "";

                return user?.name + " " + user?.surname;
              },
            },
            {
              name: "Dosyalar",
              cell: (row) => {
                if (row.files?.length === 0) return "Dosya Bulunamadı";

                return (
                  <Flex flexWrap="wrap">
                    {row.files.map((_file: any) => (
                      <Button
                        mr="1"
                        colorScheme="blue"
                        size="xs"
                        key={_file.id}
                        as="a"
                        target="_blank"
                        href={
                          getApiUrl() +
                          "/" +
                          _file.fileUrl?.split(/\\/g).join("/")
                        }
                      >
                        <Text fontSize="xs">Dosya ID: {_file.id}</Text>
                      </Button>
                    ))}
                  </Flex>
                );
              },
            },
          ]}
          data={(dataFiles?.data as unknown as any[])?.filter(
            filterDataByCampus
          )}
        />
      </Card>
    </Main>
  );
};

export default List;
