import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Heading,
  HStack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import { Controller, useForm } from "react-hook-form";
import MdField from "components/molecules/Form/MdField";
import moment from "moment";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import Dropzone from "components/atoms/Dropzone";
import { useGetUserOnStore } from "hooks/find-users-on-store";

type DailyNoteFormProps = {
  onCreate: () => void;
  date: string;
};

const DailyNoteForm: FC<DailyNoteFormProps> = (props) => {
  const { project, user } = useAuth();
  const { getUser } = useGetUserOnStore();
  const [postNote, statusNote] = Hooks.NoteCreate();
  const _form = useForm();
  const dateMoment = moment();
  const toast = useToast();
  const [files, setFiles] = useState<any | null>(null);
  const disc = useDisclosure();

  const onValid = (values: any) => {
    const body = {
      body: values.body,
      date: props.date,
      isActive: true,
      projectID: project.id,
      title: JSON.stringify(user.userTypes.map((x: any) => x.id)),
      userID: user.id,
      files: files ? files : undefined,
    };
    postNote(
      undefined,
      project.id,
      body.title,
      body.body,
      true,
      body.date,
      body.files
    );
  };

  useEffect(() => {
    if (statusNote === "fulfilled") {
      toast({ title: "Not Eklendi", status: "success" });
      _form.reset();
      _form.setValue("body", "");
      props.onCreate();
      disc.onToggle();
    } else if (statusNote === "rejected") {
      toast({ title: "Not Eklenemedi", status: "error" });
    }
  }, [statusNote]);

  return (
    <form onSubmit={_form.handleSubmit(onValid)}>
      <Card>
        <Box mb="5"></Box>
        <Controller
          control={_form.control}
          rules={{ required: true }}
          name="body"
          render={({ field }) => (
            <Textarea {...field} placeholder="Not Giriniz..." bgColor="white" />
          )}
        />
        <Dropzone
          onDrop={(files) => {
            if (files && files.length > 0) {
              setFiles(files);
            } else {
              setFiles(null);
            }
          }}
          hasAllType={false}
          multiple={true}
        />
        <HStack justifyContent="flex-end" mt="4">
          <Button type="submit">Ekle</Button>
        </HStack>
      </Card>
    </form>
  );
};

export default DailyNoteForm;
