// #region imports
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import Badge from "components/atoms/Badge";
import Confirm from "components/atoms/Confirm";
import DTable from "components/atoms/DataTable";
import Drawer, { DrawerProps } from "components/atoms/Drawer";
import InventoryFilter from "components/organisms/InventoryFilter";
import Main from "components/templates/Main";
import InventoryEditModal from "features/inventory/inventory-edit-modal/InventoryEditModal";
import { isSieOrNo } from "helper/project-mode";
import { useAuth } from "hooks/redux-hooks";
import { Inventory } from "models";
import { UserRoles } from "models/user";
import Moment from "moment";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { IoCamera, IoOptions, IoShare } from "react-icons/io5";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { mainPaths } from "router/path";
import { Hooks } from "services";
import { Campus as ICampus } from "services/swagger";
import FileUploaderForm, {
  FileUploadType,
} from "./components/FileUploaderForm";

//#endregion

function InventoryPage() {
  const { project, role } = useAuth();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const drawerRef = useRef<DrawerProps>(null);
  const [lightBoxImage, setLightBoxImage] = useState("");
  const toast = useToast();
  const filterDisc = useDisclosure();
  const [filterData, setFilterData] = useState<any>(null);
  const editDisc = useDisclosure();

  const [getList, statusList, data] = Hooks.InventoryList();
  const [update, statusUpdate, dataUpdate] = Hooks.UpdateInvetory();
  const [updateItem, setUpdateItem] = useState<number | null>(null);
  const [editItem, setEditItem] = useState<string | null>(null);

  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  function nullToUndefined(item: any) {
    if (typeof item === null || item === null) {
      return undefined;
    }
    return item;
  }

  useEffect(() => {
    const _data = localStorage.getItem("filter-data");
    if (_data) {
      filterDisc.onOpen();
    }
  }, []);

  function toggleStatus(row: Inventory) {
    setUpdateItem(row.id);
    update(
      row.id,
      row.userTypeID,
      row.projectID,
      row.campusID,
      row.buildID,
      row.floorID,
      row.roomID,
      row.groupID,
      row.brandID,
      row.modelID ?? 0,
      nullToUndefined(row.inventoryaCapacityID),
      row.barcode,
      row.assetNo,
      row.name,
      row.serialNumber,
      row.productionYear,
      row.inventoryPhoto,
      row.explain,
      row.unit,
      row.count,
      !row.isActive
    );
  }

  useEffect(() => {
    if (statusUpdate === "fulfilled") {
      if (dataUpdate?.data.success) {
        setUpdateItem(null);
        toast({ status: "success", title: "Ekipman başarıyla güncellendi!" });
        getList(project.id);
      }
    }
  }, [statusUpdate]);

  function _onFilterData(row: Inventory) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData?.campus?.value, row.campusID),
      _condition(filterData?.build?.value, row.buildID),
      _condition(filterData?.floor?.value, row.floorID),
      _condition(filterData?.room?.value, row.roomID),
      _condition(filterData?.userType?.value, row.userTypeID),
      _condition(filterData?.group?.value, row.groupID),
      _condition(filterData?.brand?.value, row.brandID),
      _condition(filterData?.model?.value, row.modelID),
    ];

    return !_data.includes(false);
  }

  useEffect(() => {
    if (!filterDisc.isOpen) {
      setFilterData({});
    }
  }, [filterDisc.isOpen]);

  return (
    <Main headerProps={{ title: "Ekipman Listesi" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="orange"
            mr="3"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
          {role !== UserRoles.basic && role !== UserRoles.view && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => history.push("/inventory/create")}
            >
              Yeni Ekipman Ekle
            </Button>
          )}
        </Flex>
      </Card>

      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      {!!filterData && Object.values(filterData).length > 0 && (
        <Flex flexWrap="wrap" mb="3">
          {Object.values(filterData).map((data: any) =>
            !!data?.label ? (
              <Center p="2" px="4" bgColor="white" mr="3">
                <Text fontSize="xs" textAlign="center">
                  {data?.label}
                </Text>
              </Center>
            ) : null
          )}
        </Flex>
      )}

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Barkod",
                selector: "barcode",
                sortable: true,
                width: "150px",
              },
              {
                name: "Ekipman Adı",
                selector: "name",
                sortable: true,
                width: "150px",
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Açıklama",
                selector: "explain",
                sortable: true,
                width: "200px",
              },
              {
                name: "Adet",
                selector: "count",
                sortable: true,
                width: "100px",
              },
              {
                name: "Tesis",
                selector: "campusName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Bina",
                selector: "buildName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Kat",
                selector: "floorName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Oda",
                selector: "roomName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Grup",
                selector: "groupName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Marka",
                selector: "brandName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Model",
                selector: "modelName",
                sortable: true,
              },
              {
                name: "Seri No",
                selector: "serialNumber",
                sortable: true,
              },
              {
                name: "Asset No",
                selector: "assetNo",
                sortable: true,
              },
              {
                name: "Kapasite Tipi",
                selector: "inventoryaCapacityName",
                sortable: true,
              },
              {
                name: "Kapasite Değeri",
                selector: "unit",
                sortable: true,
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
                width: "200px",
                columnType: "date",
                selector: "createDate",
              },
              {
                name: "Oluşturan Kullanıcı",
                width: "200px",
                selector: "createUserName",
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
                width: "100px",
                columnType: "isActiveBoolean",
                selector: "isActive",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: Inventory) => (
                  <Center w="90%">
                    <Confirm
                      onConfirm={() => {
                        toggleStatus(row);
                      }}
                      text={`Bu ekipman ${
                        !row.isActive ? "Aktif" : "Pasif"
                      } yapılsın mı? `}
                    >
                      {({ onOpen }) => (
                        <IconButton
                          isLoading={updateItem === row.id}
                          size="sm"
                          onClick={onOpen}
                          aria-label="activepassive"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Center>
                ),
                width: "100px",
              },
              {
                name: "Fotoğraf",
                cell: (row: any) => (
                  <Center w="100%">
                    {!!!row.inventoryPhoto ? (
                      "Fotoğraf Yok"
                    ) : (
                      <IconButton
                        size="sm"
                        aria-label="activepassive"
                        onClick={() => {
                          setLightBoxImage(row.inventoryPhoto);
                        }}
                        icon={<IoCamera />}
                      />
                    )}
                  </Center>
                ),
                width: "100px",
                selector: "inventoryPhoto",
              },
              {
                name: "Dosyaları Düzenle",
                cell: (row: any) => {
                  return (
                    <Button
                      colorScheme="blue"
                      disabled={
                        role === UserRoles.view || role === UserRoles.basic
                      }
                      size="sm"
                      onClick={() => {
                        setSelectedInventory(row);
                        drawerRef.current?.open();
                      }}
                    >
                      Dosyalar
                    </Button>
                  );
                },
                width: "120px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <ButtonGroup
                    size="sm"
                    isAttached
                    colorScheme="teal"
                    rounded="sm"
                  >
                    <Button
                      onClick={() => {
                        setEditItem(row.barcode);
                        editDisc.onOpen();
                      }}
                      disabled={
                        role === UserRoles.view || role === UserRoles.basic
                      }
                    >
                      Düzenle
                    </Button>
                    <IconButton
                      borderLeftWidth={1}
                      onClick={() => {
                        window.open(
                          `${mainPaths.InventoryEdit}/${row.barcode}`
                        );
                      }}
                      rounded="sm"
                      aria-label="new-target"
                      icon={<IoShare />}
                    />
                  </ButtonGroup>
                ),
                width: "120px",
              },
              // {
              //   name: "Bakımlar",
              //   cell: (row: any) => (
              //     <Link to={`/maintenance-list/${row.id}`}>
              //       <Button colorScheme="purple" size="sm">
              //         Bakımlar
              //       </Button>
              //     </Link>
              //   ),
              //   width: "140px",
              // },
              {
                name: "Bakımlar",
                cell: (row: any) => (
                  <Button
                    colorScheme="purple"
                    size="sm"
                    onClick={() => {
                      history.push(`/maintenance-list/${row.id}`, {
                        inventory: row,
                      });
                    }}
                  >
                    Bakımlar
                  </Button>
                ),
                width: "140px",
              },
              {
                name: `Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}`,
                cell: (row: any) => (
                  <Button
                    colorScheme="orange"
                    size="sm"
                    onClick={() => {
                      history.push(`/periodic-controls/${row.id}`, {
                        inventory: row,
                      });
                    }}
                  >
                    Periyodik {isSieOrNo("Kontroller", "Muayeneler")}
                  </Button>
                ),
                width: "180px",
              },
            ]}
            data={data?.data.data
              .filter(_onFilterData)
              .map((item: Inventory) => ({
                ...item,
                highlight: item.hasMaintenance,
              }))
              .sort((a: any, b: any) => -1 * (a.isActive - b.isActive))}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      {editItem && (
        <InventoryEditModal
          disc={editDisc}
          id={editItem}
          onUpdate={() => {
            getList(project.id);
          }}
        />
      )}

      <Drawer
        onConfirmText="Tamam"
        onConfirm={() => {
          drawerRef.current?.close();
          getList(project.id);
        }}
        ref={drawerRef}
        onClose={() => {
          getList(project.id);
        }}
        title={`${selectedInventory?.name} Dosyaları`}
      >
        <FileUploaderForm
          uploadType={FileUploadType.UserGuidePath}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={selectedInventory?.userGudiePath as unknown as string}
          title={"Kullanım Klavuzu"}
          onNeedReload={() => {}}
        />
        <FileUploaderForm
          uploadType={FileUploadType.UserInstructions}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.userInstructions as unknown as string
          }
          title={"Kullanım Talimatı"}
        />
        <FileUploaderForm
          uploadType={FileUploadType.RiskAnalysisPath}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.riskAnalysisPath as unknown as string
          }
          title={"ISG Risk Analizi"}
          date={Moment(selectedInventory?.riskAnalysisExpriDate).format(
            "YYYY-MM-DD"
          )}
        />
        <FileUploaderForm
          uploadType={FileUploadType.MaintenanceContract}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.maintenanceContract as unknown as string
          }
          title={"Bakım Sözleşmesi"}
        />
      </Drawer>
      {!!lightBoxImage && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setLightBoxImage("")}
        />
      )}
    </Main>
  );
}

export default InventoryPage;
