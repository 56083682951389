import { Box } from "@chakra-ui/react";
import moment from "moment";
import { FC, useEffect, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "../../../utils/calendar.css";

type NoteCalendarProps = {
  events: any[];
  onClick: (event: any) => void;
  onRangeChange: (range: { start: Date; end: Date } | Date[]) => void;
};

const localizer = momentLocalizer(moment);

const NoteCalendar: FC<NoteCalendarProps> = (props) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const todayButton: any = document.querySelector(
      ".rbc-toolbar button:nth-child(1)"
    );
    todayButton?.click();
  }, []);

  return (
    <Box p="3" bgColor="white" mt="5" rounded="md">
      <Calendar
        ref={ref}
        onSelectEvent={props.onClick}
        localizer={localizer}
        events={props.events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        showMultiDayTimes={false}
        onRangeChange={(range) => {
          props.onRangeChange(range as any);
        }}
      />
    </Box>
  );
};

export default NoteCalendar;
