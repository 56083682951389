import { Center, Spinner } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { useGetUserOnStore } from "hooks/find-users-on-store";
import { useAuth } from "hooks/redux-hooks";
import { UserRoles } from "models/user";
import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as R from "remeda";
import { Hooks } from "services";
import "../../utils/calendar.css";

const localizer = momentLocalizer(moment);

const TrackEmployeePage = () => {
  const { project } = useAuth();
  const [getNotes, statusNotes, dataNotes] = Hooks.NoteODataWithParamsBetween();
  const [, statUsers, dataUsers] = Hooks.UsersStore();
  const { getUser, getUsers } = useGetUserOnStore();
  const [events, setEvents] = useState([] as any[]);

  function getCurrentDateRange() {
    const firstDayOfWeek = moment().subtract(moment().get("weekday"), "days");
    const lastDayOfWeek = moment().add(6 - moment().get("weekday"), "days");

    const dateRange: Date[] = [];

    for (
      let currDate = firstDayOfWeek;
      currDate.valueOf() <= lastDayOfWeek.valueOf();
      currDate.add("1", "days")
    ) {
      dateRange.push(currDate.toDate());
    }

    handleChangeRange(dateRange);
  }

  function manageColorStatus() {
    const elements = document.querySelectorAll(".rbc-event-content");
    if (elements) {
      elements.forEach((element) => {
        if (element.textContent?.includes("-")) {
          (element as any).parentElement.style.backgroundColor = "gray";
        }
      });
    }
  }

  useEffect(() => {
    manageColorStatus();
  }, [events]);

  async function handleChangeRange(range: Date[]) {
    const notes = await getNotes(
      moment(range[0]).subtract(1, "days").format("YYYY-MM-DD"),
      moment(range[6]).add(1, "days").format("YYYY-MM-DD")
    ).then((res) => res.data);
    if (!notes) {
      setEvents([]);
      return;
    }

    if (notes.length === 0) {
      setEvents([]);
      return;
    }
    const users = dataUsers!.data.data;
    const filteredUsers = R.pipe(
      users,
      R.filter((x) => {
        const isStandart = x.userRoleID === UserRoles.basic;
        return isStandart && x.isActive && !x.name.toLowerCase().includes("test");
      })
    );

    let _events = [] as any[];
    range.forEach((date) => {
      const dateUsers = [] as any[];

      filteredUsers.forEach((user) => {
        const hasNote = R.pipe(
          notes,
          R.filter((x: any) => {
            return (
              x.userID === user.id &&
              moment(x.date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
            );
          })
        );

        if (moment(date).isSameOrAfter(moment(user.createDate))) {
          dateUsers.push({
            start: date,
            end: date,
            disabled: true,
            disable: true,
            title: `${user.name} ${user.surname} ${
              hasNote.length > 0 ? "+" : "-"
            }`,
          });
        }
      });

      _events = [..._events, ...dateUsers];
    });

    setEvents(_events);
  }

  useLayoutEffect(() => {
    if (statUsers === "fulfilled" && dataUsers?.data.data) {
      getCurrentDateRange();
    }
  }, [statUsers]);

  return (
    <Main headerProps={{ title: "Not Takibi" }}>
      <div className="note-trace">
        {statusNotes === "pending" && (
          <Center>
            <Spinner />
          </Center>
        )}
        <Calendar
          view="week"
          defaultView="week"
          onSelectEvent={() => {}}
          localizer={localizer}
          events={events}
          style={{ height: 500 }}
          showMultiDayTimes={false}
          onRangeChange={(range) => {
            handleChangeRange(range as any);
          }}
          allDayAccessor="start"
          formats={{
            eventTimeRangeFormat: undefined,
          }}
        />
      </div>
    </Main>
  );
};

export default TrackEmployeePage;
