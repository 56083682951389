import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Hooks } from "services";
import { RouteComponentProps } from "react-router-dom";
import { Card, DataTable, Modal, ModalProps } from "components/atoms";
import { IoArrowForward, IoRefresh } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/toast";
import CustomBadge from "components/atoms/Badge";

const QuestionList: FC<RouteComponentProps> = (props) => {
  const [getQuestions, status, data] = Hooks.ListQuestion();
  const [updateQuestion, upStatus, upData] = Hooks.UpdateMaintenanceQuestion();
  const modalRef = useRef<ModalProps>(null);
  const { register, handleSubmit, watch, reset, setValue } = useForm();
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const toast = useToast();

  function onValid(_questionValues: any) {
    updateQuestion({
      ...selectedQuestion,
      ...(_questionValues as any),
      orders: Number(_questionValues.orders),
      trueFalseAnswer: _questionValues.positive === "1",
      inventoryMaintenanceTypeID: (props.location.state as any).maintenance.id,
      isNegativeAnswerBlockMaintenance:
        _questionValues.isNegativeAnswerBlockMaintenance,
      maintenanceQuestionTypeID: parseInt(
        _questionValues.maintenanceQuestionTypeID
      ),
    });
  }

  function _selectQuestion(_question: any) {
    setSelectedQuestion(_question);
    modalRef.current?.open();
    register("maintenanceQuestionTypeID");
    setValue(
      "maintenanceQuestionTypeID",
      _question.maintenanceQuestionTypeID.toString()
    );
    setValue("question", _question.question);
    setValue("orders", _question.orders);
    setValue("initialValue", _question.initialValue);
    setValue("endValue", _question.endValue);
    setValue("positive", _question.trueFalseAnswer ? "1" : "2");
    setValue(
      "isNegativeAnswerBlockMaintenance",
      _question.isNegativeAnswerBlockMaintenance
    );
  }

  useEffect(() => {
    if (upStatus === "fulfilled") {
      getQuestions((props.location.state as any).maintenance.id);
      modalRef.current?.close();
      toast({ status: "success", title: "Başarılı!" });
    } else if (upStatus === "rejected") {
      toast({ status: "error", title: "Bir Hata Oluştu!" });
      modalRef.current?.close();
    }
  }, [upStatus]);

  useLayoutEffect(() => {
    getQuestions((props.location.state as any).maintenance.id);
  }, []);

  return (
    <Main headerProps={{ title: "Bakım Soruları" }}>
      <Flex>
        <Heading color="gray.600" size="md" mb="3">
          Ekipman Adı: {(props.location.state as any)?.inventory?.name}
        </Heading>
        <Heading color="gray.600" size="md" mb="3" ml="3">
          Barkod: {(props.location.state as any)?.inventory?.barcode}
        </Heading>
      </Flex>

      {status === "fulfilled" && (
        <Card>
          <DataTable
            data={data?.data.data.sort((a: any, b: any) => a.orders - b.orders)}
            columns={[
              {
                name: "ID",
                selector: "id",
              },
              {
                name: "Soru Tipi",
                cell: (item) => {
                  const isValueses =
                    item.endValue === "-2560" && item.initialValue === "-2560";
                  return (
                    <Box>
                      <Badge
                        size="sm"
                        colorScheme={
                          item.maintenanceQuestionTypeID === 1
                            ? "purple"
                            : "teal"
                        }
                      >
                        {isValueses ? (
                          "Aralıksız Değer Girişli"
                        ) : item.maintenanceQuestionTypeID === 1 ? (
                          "Değer Girişli"
                        ) : (
                          <Flex>
                            <Text
                              borderBottomWidth={
                                item.trueFalseAnswer ? "1px" : undefined
                              }
                              d="inline"
                            >
                              Evet
                            </Text>{" "}
                            /{" "}
                            <Text
                              borderBottomWidth={
                                !item.trueFalseAnswer ? "1px" : undefined
                              }
                            >
                              Hayır
                            </Text>
                          </Flex>
                        )}
                      </Badge>
                    </Box>
                  );
                },
              },
              {
                name: "Soru Sırası",
                selector: "orders",
              },
              {
                name: "Soru",
                selector: "question",
              },
              {
                name: "Başlangıç Değeri",
                cell: (item) => {
                  const isValueses =
                    item.endValue === "-2560" && item.initialValue === "-2560";

                  if (isValueses) return "";

                  return item.initialValue;
                },
              },
              {
                name: "Bitiş Değeri",
                selector: "endValue",
                cell: (item) => {
                  const isValueses =
                    item.endValue === "-2560" && item.initialValue === "-2560";

                  if (isValueses) return "";

                  return item.endValue;
                },
              },
              {
                name: "Olumlu Yanıt",
                cell: (row) => (row.trueFalseAnswer ? "Evet" : "Hayır"),
              },
              {
                name: "Olumsuz yanıt verilemez",
                cell: (row) =>
                  row.isNegativeAnswerBlockMaintenance ? "Aktif" : "Pasif",
              },
              {
                name: "Durum",
                cell: (row) => <CustomBadge isActive={row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row) => (
                  <Box>
                    <IconButton
                      isDisabled={upStatus === "pending"}
                      aria-label="active"
                      icon={<IoRefresh />}
                      size="sm"
                      onClick={() => {
                        updateQuestion({
                          ...row,
                          isActive: !row.isActive,
                        });
                      }}
                    />
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row) => (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => _selectQuestion(row)}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
            ]}
          />
        </Card>
      )}

      <Modal
        ref={modalRef}
        title="Soru Düzenle"
        onConfirm={() => {
          handleSubmit(onValid)();
        }}
        isConfirmSpinner={upStatus === "pending"}
        onConfirmText="Soru Düzenle"
        onClose={() => {
          reset();
        }}
      >
        <form>
          <Flex flexDir="column">
            <Box>
              <FormControl>
                <Select
                  disabled
                  placeholder="Soru Tipi"
                  value={watch("maintenanceQuestionTypeID")}
                >
                  <option value="1">DEĞER GİRME ALANI</option>
                  <option value="2">EVET HAYIR</option>
                </Select>
              </FormControl>
              <FormControl>
                <Textarea mt="1" placeholder="Soru" {...register("question")} />
              </FormControl>
              {!(
                watch("endValue") === "-2560" &&
                watch("initialValue") === "-2560"
              ) && (
                <>
                  {watch("maintenanceQuestionTypeID") === "1" && (
                    <>
                      <FormControl mt="2">
                        <Input
                          {...register("initialValue")}
                          placeholder="Başlangıç Değeri"
                        />
                      </FormControl>
                      <FormControl mt="2">
                        <Input
                          {...register("endValue")}
                          placeholder="Bitiş Değeri"
                        />
                      </FormControl>
                    </>
                  )}
                  {watch("maintenanceQuestionTypeID") === "2" && (
                    <FormControl mt="2">
                      <FormLabel>Olumlu Olan Yanıt</FormLabel>
                      <Select {...register("positive")}>
                        <option value="1">EVET</option>
                        <option value="2">HAYIR</option>
                      </Select>
                    </FormControl>
                  )}
                  <Box mt="2">
                    <Checkbox {...register("isNegativeAnswerBlockMaintenance")}>
                      Olumsuz yanıt verilemez?
                    </Checkbox>
                  </Box>
                </>
              )}

              <FormControl mt="3">
                <FormLabel>Soru Sırası</FormLabel>
                <Input
                  type="number"
                  mt="1"
                  placeholder="Soru Sırası"
                  {...register("orders")}
                />
              </FormControl>
            </Box>
          </Flex>
        </form>
      </Modal>
    </Main>
  );
};

export default QuestionList;
