import { Hooks } from "services";
import { useAuth } from "./redux-hooks";

export const useGetUserOnStore = () => {
  const [_getUsers, statUsers, dataUsers] = Hooks.UsersStore();

  function getUser(id: number) {
    if (dataUsers?.data?.data) {
      return dataUsers.data.data.find((user) => user.id === id);
    }

    return null;
  }

  function getUsers() {
    return dataUsers?.data.data;
  }

  return { getUser, getUsers };
};
