import {
  Button,
  Center,
  Flex,
  IconButton,
  Select,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { default as Moment } from "moment";
import {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FaFile } from "react-icons/fa";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { RouteComponentProps } from "react-router-dom";
import RSelect from "react-select";
import { Hooks } from "services";
import { Card } from "components/atoms";
import { ModalProps } from "components/atoms/Modal";
import ODataTable from "components/atoms/ODataTable";
import InventoryODataFilter from "components/organisms/InventoryODataFilter";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";

interface CompletedMaintenanceProps {}

type FilterProperty = {
  label: string;
  value: number;
};
var filterRequestState = false;
const CompletedMaintenance: FC<
  CompletedMaintenanceProps & RouteComponentProps
> = (props) => {
  const { project } = useAuth();
  const [getOdata, statOdata, dataoData] = Hooks.CompletedReportOData();
  const [getDocsReports, statDocsReports, dataDocsReports, reset] =
    Hooks.ShowDocsReports();
  const [getExcelOdata, statExcelOdata, dataExceloData] =
    Hooks.CompletedReportExcelOData();
  const [getCount, statCount, dataCount] = Hooks.MaintenanceCount();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [countTotalState, setCountTotalState] = useState<any>();
  const [countState, setCountState] = useState<any>();
  const [tableShowCount, setTableShowCount] = useState(10);
  const [skipCount, setSkipCount] = useState(0);
  const [filterData, setFilterData] = useState<any[]>([]);
  const filterModalRef = useRef<ModalProps>(null);
  const [filteredType, setFilteredType] = useState({
    label: "Hepsi",
    value: "0",
  });
  const [isMaintenanceCompleted, setIsMaintenanceCompleted] = useState(3);
  const [reportId, setReportId] = useState<number | null>(null);

  const filterDisc = useDisclosure();

  useLayoutEffect(() => {
    getPeriods(project.id);
    getUT();
    getOdata(project.id, "", 100, skipCount, "false");
  }, []);

  useEffect(() => {
    if (statCount === "fulfilled") {
      const count =
        (dataCount?.data as unknown as IMaintenanceCount)
          ?.notCompletedMaintenanceCount +
        (dataCount?.data as unknown as IMaintenanceCount)
          ?.completedMaintenanceCount;
      setCountState(count);
      setCountTotalState(count);
      getOdata(project.id, "", tableShowCount, skipCount, "false");
    }
  }, [statCount]);

  useEffect(() => {
    if (statOdata === "fulfilled") {
      const count = (dataoData as unknown as IMaintenanceOData)["@odata.count"];
      if (filterRequestState) {
        setCountState(count);
        setCountTotalState(count);
      }
    }
  }, [statOdata]);

  useEffect(() => {
    if (statDocsReports == "fulfilled") {
      localStorage.setItem(
        "doc-report",
        JSON.stringify(dataDocsReports?.data as unknown as any[])
      );
      reset();
      window.open("/doc-report", "_blank");
    }
  }, [statDocsReports]);

  useEffect(() => {
    if (filterData !== null && Object.keys(filterData).length > 0) {
      localStorage.setItem("filter-odata", JSON.stringify(filterData));
      useFilters(tableShowCount, skipCount);
    }
  }, [filterData]);

  async function ReportShow(row: any) {
    setReportId(row.MaintenanceId);
    localStorage.removeItem("doc-report");
    await getDocsReports(project.id, row.MaintenanceId);
    setReportId(null);
  }

  function useFilters(top: number, skipCount: number) {
    var data = {};
    const _filterData = localStorage.getItem("filter-odata");
    if (_filterData !== null) {
      data = JSON.parse(_filterData);
    } else {
      data = {};
    }
    var queryFilter = "filter=";
    if (Object.keys(data).length > 0) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const property = data[key as keyof typeof data] as FilterProperty;
          if (typeof property !== "string") {
            const label = property?.label;
            if (label) {
              if (
                key == "isMaintenanceCompleted" ||
                key == "maintenanceTypeID"
              ) {
                if (property?.value !== 3) {
                  queryFilter =
                    queryFilter +
                    (queryFilter.slice(-1) === "="
                      ? `${key} eq ${label}`
                      : ` and ${key} eq ${label}`);
                }
              } else {
                queryFilter =
                  queryFilter +
                  (queryFilter.slice(-1) === "="
                    ? `${key} eq '${label}'`
                    : ` and ${key} eq '${label}'`);
              }
            }
          } else {
            key == "startDate"
              ? (queryFilter =
                  queryFilter +
                  (queryFilter.slice(-1) === "="
                    ? `startDate gt ${property}`
                    : ` and startDate gt ${property}`))
              : (queryFilter =
                  queryFilter +
                  (queryFilter.slice(-1) === "="
                    ? `endDate lt ${property}`
                    : ` and endDate lt ${property}`));
          }
        }
      }
      if (queryFilter.endsWith("=")) {
        getOdata(project.id, "", top, skipCount, "true");
      } else if (!filterRequestState) {
        getOdata(project.id, "", top, skipCount, "false");
      } else {
        getOdata(project.id, queryFilter, top, skipCount, "true");
        getExcelOdata(project.id, queryFilter);
      }
    } else {
      getOdata(project.id, "", top, skipCount, "true");
    }
  }

  return (
    <Main headerProps={{ title: "Bakım Raporları" }}>
      {statOdata !== "fulfilled" ? (
        <Center h="300px">
          <Spinner />
        </Center>
      ) : (
        <Fragment>
          <Card mb="3">
            <Flex justifyContent="space-between" alignItems="center">
              <Flex>
                <Select
                  rounded="md"
                  defaultValue={isMaintenanceCompleted}
                  onChange={(e) => {
                    const selected = e.target.value;
                    const _filterData = localStorage.getItem("filter-odata");
                    if (_filterData !== null) {
                      let tmpFilterData = JSON.parse(_filterData);
                      setFilterData((state: any) => ({
                        ...state,
                        ...tmpFilterData,
                      }));
                    } else {
                      setFilterData([]);
                    }
                    filterRequestState = true;
                    switch (selected) {
                      case "1":
                        setFilterData((state: any) => ({
                          ...state,
                          isMaintenanceCompleted: {
                            label: "true",
                            value: "1",
                          },
                        }));
                        setIsMaintenanceCompleted(1);
                        break;
                      case "2":
                        setFilterData((state: any) => ({
                          ...state,
                          isMaintenanceCompleted: {
                            label: "false",
                            value: "2",
                          },
                        }));
                        setIsMaintenanceCompleted(2);
                        break;
                      default:
                        setFilterData((state: any) => ({
                          ...state,
                          isMaintenanceCompleted: {
                            label: "",
                            value: "3",
                          },
                        }));
                        setIsMaintenanceCompleted(3);
                        break;
                    }
                  }}
                >
                  <option value="3">
                    Gerçekleşen Ve Zamanında Gerçekleşmeyen
                  </option>
                  <option value="1">Gerçekleşen</option>
                  <option value="2">Zamanında Gerçekleşmeyen</option>
                </Select>
              </Flex>
              <Button
                size="sm"
                colorScheme="orange"
                onClick={() => filterDisc.onToggle()}
              >
                {filterDisc.isOpen ? "Filtre Kapat" : "Detaylı Filtre"}
              </Button>
            </Flex>
          </Card>

          {filterDisc.isOpen && (
            <InventoryODataFilter
              onFilter={(data: any) => {
                filterRequestState = true;
                useFilters(tableShowCount, skipCount);
              }}
            />
          )}
          <Card>
            <RSelect
              options={[
                { label: "Hepsi", value: "0" },
                { label: "İç Bakım", value: "1" },
                { label: "Dış Bakım", value: "2" },
              ]}
              defaultValue={filteredType}
              onChange={(value) => {
                filterRequestState = true;
                const _filterData = localStorage.getItem("filter-odata");
                if (_filterData !== null) {
                  let tmpFilterData = JSON.parse(_filterData);
                  setFilterData((state: any) => ({
                    ...state,
                    ...tmpFilterData,
                  }));
                } else {
                  setFilterData([]);
                }
                switch (String(value?.value)) {
                  case "1":
                    setFilterData((state: any) => ({
                      ...state,
                      maintenanceTypeID: {
                        label: "1",
                        value: "1",
                      },
                    }));
                    break;
                  case "2":
                    setFilterData((state: any) => ({
                      ...state,
                      maintenanceTypeID: {
                        label: "2",
                        value: "2",
                      },
                    }));
                    break;
                  default:
                    break;
                }
                value && setFilteredType(value);
              }}
            />
          </Card>
          <Card>
            <ODataTable
              onClickFilter={() => {
                filterModalRef.current?.open();
              }}
              columns={[
                {
                  name: "Kullanıcı Tipi",
                  selector: "UserTypeName",
                  width: "200px",
                },
                { name: "Barkod", selector: "Barcode" },
                { name: "Ekipman Adı", selector: "Name", width: "150px" },
                {
                  name: "Bakım Raporu",
                  cell: (row: any) => {
                    if (!row.IsMaintenanceCompleted) {
                      return "Gerçekleşmedi";
                    }
                    return (
                      <div>
                        <Button
                          isLoading={reportId === row.MaintenanceId}
                          size="xs"
                          colorScheme="blue"
                          onClick={() => ReportShow(row)}
                          leftIcon={<FaFile />}
                        >
                          Raporu Görüntüle
                        </Button>
                      </div>
                    );
                  },
                  width: "200px",
                },
                {
                  name: "Bakım Tipi",
                  selector: "MaintenanceTypeName",
                  width: "150px",
                },
                {
                  name: "Periyot",
                  selector: "MaintenancePeriodName",
                  width: "150px",
                },
                {
                  name: "Başlangıç Tarihi",
                  selector: "startDateStr",
                  width: "150px",
                },
                {
                  name: "Planlı Bakım Tarihi",
                  selector: "endDateStr",
                  width: "150px",
                },
                {
                  name: "Gerçekleşen Tarih",
                  selector: "maintenanceDateStr",
                  cell: (row: any) => {
                    if (!row.IsMaintenanceCompleted) {
                      return "Gerçekleşmedi";
                    }

                    return row.maintenanceDateStr;
                  },
                  width: "150px",
                },
                {
                  name: "Bakım Firması",
                  selector: "MaintenanceFirm",
                  width: "150px",
                },
                { name: "Kullanıcı", selector: "PersonelName", width: "150px" },
                { name: "Tesis", selector: "CampusName", width: "150px" },
                { name: "Bina", selector: "BuildName", width: "150px" },
                { name: "Kat", selector: "FloorName", width: "150px" },
                { name: "Oda", selector: "RoomName", width: "150px" },
                { name: "Grup", selector: "GroupName", width: "150px" },
                { name: "Marka", selector: "BrandName", width: "150px" },
                { name: "Model", selector: "ModelName", width: "150px" },
                {
                  name: "Kapasite Tipi",
                  selector: "InventoryCapacityName",
                  width: "150px",
                },
                {
                  name: "Kapasite Birimi",
                  selector: "Unit",
                  width: "150px",
                },
                { name: "Adet", selector: "Count", width: "100px" },
                {
                  name: "Üretim Yılı",
                  selector: "ProductionYear",
                  width: "150px",
                },
              ]}
              data={(dataoData as unknown as IMaintenanceOData).value?.map(
                (row: any) => ({
                  ...row,
                  startDateStr: Moment(row.StartDate).format("DD.MM.YYYY"),
                  endDateStr: Moment(row.EndDate).format("DD.MM.YYYY"),
                  maintenanceDateStr: Moment(row.MaintenanceDate).format(
                    "DD.MM.YYYY HH:mm"
                  ),
                })
              )}
              showBulkreport
              dataCount={countTotalState}
              tableShowCount={tableShowCount}
              excelData={(
                dataExceloData as unknown as IMaintenanceOData
              )?.value?.map((row: any) => ({
                ...row,
                startDateStr: Moment(row.StartDate).format("DD.MM.YYYY"),
                endDateStr: Moment(row.EndDate).format("DD.MM.YYYY"),
                maintenanceDateStr: Moment(row.MaintenanceDate).format(
                  "DD.MM.YYYY HH:mm"
                ),
              }))}
              bottomTable={
                <>
                  <Flex alignItems="center">
                    <Select
                      size="xs"
                      w="100px"
                      defaultValue={tableShowCount}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setTableShowCount(value);
                        useFilters(value, skipCount);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                    </Select>
                    <Text color="gray.600" mt="0.5" fontSize="xs" ml="3">
                      Adet
                    </Text>
                  </Flex>
                  <Flex alignItems="center" pr="10">
                    <IconButton
                      disabled={skipCount + 1 === 1}
                      onClick={() => {
                        setSkipCount(skipCount - 1);
                        useFilters(
                          tableShowCount,
                          (skipCount - 1) * tableShowCount
                        );
                      }}
                      aria-label="back-table"
                      icon={<IoChevronBack />}
                      size="xs"
                      variant="ghost"
                    />
                    <Text fontSize="xs" mx="5">
                      {skipCount + 1} /{" "}
                      <Text d="inline" color="gray.500">
                        {Math.ceil(countState / tableShowCount)}
                      </Text>
                    </Text>
                    <IconButton
                      disabled={
                        skipCount + 1 === Math.ceil(countState / tableShowCount)
                      }
                      onClick={() => {
                        setSkipCount(skipCount + 1);
                        useFilters(
                          tableShowCount,
                          (skipCount + 1) * tableShowCount
                        );
                      }}
                      aria-label="forward-table"
                      icon={<IoChevronForward />}
                      size="xs"
                      variant="ghost"
                    />
                  </Flex>
                </>
              }
            />
          </Card>
        </Fragment>
      )}
      {/* <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl>
          <FormLabel>Periyot</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            defaultValue={
              filterData.find((item) => item.name === "maintenancePeriodID")
                ?.selection
            }
            onChange={(value) => {
              const keyName = "maintenancePeriodID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                    selection: value,
                  },
                ]);
              }
            }}
            options={dataPeriods?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
        <FormControl mt="5">
          <FormLabel>Bakım Tipi</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            defaultValue={
              filterData.find((item) => item.name === "maintenanceTypeID")
                ?.selection
            }
            onChange={(value) => {
              const keyName = "maintenanceTypeID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                    selection: value,
                  },
                ]);
              }
            }}
            options={[
              {
                label: "İç Bakım / Konrol",
                value: 1,
              },
              {
                label: "Dış Bakım",
                value: 2,
              },
            ]}
          />
        </FormControl>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            defaultValue={
              filterData.find((item) => item.name === "userTypeId")?.selection
            }
            onChange={(value) => {
              const keyName = "userTypeId";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                    selection: value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom> */}
    </Main>
  );
};

CompletedMaintenance.defaultProps = {};

export default CompletedMaintenance;
