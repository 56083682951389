import React, { FC, useEffect } from "react";
import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import { Hooks } from "services";
import "../Reports/DocReport/master.css";
import moment from "moment";
import DailyNoteItem from "features/daily-note/daily-note-list/DailyNoteItem";
import { useAuth } from "hooks/redux-hooks";
import { useGetUserOnStore } from "hooks/find-users-on-store";

type NotePdfProps = {};

const NotePdf: FC<NotePdfProps> = (props) => {
  const { project } = useAuth();
  const [getNotes, statusNotes, dataNotes] = Hooks.NoteOData();
  const { getUser } = useGetUserOnStore();

  const getUserFullname = (user?: IUser | undefined | null) => {
    if (!user) return "";

    return `${user.name} ${user.surname}`;
  };

  function noteFilter(note: any) {
    const filterStr = localStorage.getItem("note-filter");

    if (!filterStr) return true;

    const filterState = JSON.parse(filterStr!);

    if (filterState.user !== "0") {
      const noteUser = getUser(note.userID);
      const noteUserFullname = getUserFullname(noteUser);
      if (noteUserFullname !== filterState.user) {
        return false;
      }
    }

    if (filterState.startDate) {
      if (
        moment(note.date).diff(
          moment(filterState.startDate, "YYYY-MM-DD"),
          "seconds"
        ) < 0
      ) {
        return false;
      }
    }
    if (filterState.endDate) {
      if (
        moment(note.date).diff(
          moment(filterState.endDate, "YYYY-MM-DD"),
          "seconds"
        ) > 0
      ) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <>
      <div className="book">
        <div className="page">
          <Heading>Tüm Notlar</Heading>
          {statusNotes === "fulfilled" ? (
            dataNotes &&
            (dataNotes?.data as any)
              .filter(noteFilter)
              .map((item: any, index: number, array: any[]) => (
                <>
                  {(index === 0 || item.date !== array[index - 1].date) && (
                    <Heading px="2" size="md" mb="4" mt="10">
                      {moment(item.date).format("DD MMMM YYYY dddd")} Notları
                    </Heading>
                  )}
                  <DailyNoteItem forceShowImage index={index} item={item} />
                </>
              ))
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};

export default NotePdf;
