import React, { useLayoutEffect, useRef } from "react";
import {
  Text,
  Box,
  Button,
  SimpleGrid,
  Skeleton,
  Flex,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { InputField, MyForm, FormProps } from "components/molecules";
import { Card } from "components/atoms";
import NumberWidget from "components/molecules/NumberWidget";
import NumberWidgetTwo from "components/molecules/NumberWidgetTwo";
import {
  IoClipboardOutline,
  IoExitOutline,
  IoEnterOutline,
  IoConstructOutline,
} from "react-icons/io5";
import { useResponsive } from "hooks/responsive";
import ProgressListWidget from "components/molecules/ProgressListWidget";
import TimelineWidget from "components/organisms/TimelineWidget";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { HomeStats } from "models";
import TimelineWrapper from "components/organisms/TimelineWrapper";
import MaintenanceNotes from "components/organisms/MaintenanceNotes";
import NegativeQuestions from "components/organisms/NegativeQuestion";
import AdvancedNumberWidget from "components/molecules/AdvancedNumberWidget";
import { Authenticator } from "helper/authenticator";

interface SomeForm {
  email: string;
  name: string;
  surname: string;
}

function Dashboard() {
  const { project, refreshUser } = useAuth();
  const formRef = useRef<FormProps<SomeForm>>(null);
  const [getStats, statStats, dataStats] = Hooks.HomeStatistic();
  const { isSm } = useResponsive();

  useLayoutEffect(() => {
    getStats(project.id);
    refreshUser();
  }, []);

  return (
    <Main headerProps={{ title: "Kontrol Merkezi" }}>
      <Flex
        flexDir={["column", "column", "row"]}
        mt="5"
        mb="5"
        h="calc(100vh - 300px)"
        alignItems="stretch"
      >
        <Box flex={1}>
          <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
            <TimelineWrapper />
          </Skeleton>
        </Box>
      </Flex>
    </Main>
  );
}

export default Dashboard;
