import React, { FC, useLayoutEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Hooks } from "services";
import moment from "moment";

type ListProps = {};

const List: FC<ListProps> = (props) => {
  const [getN, statN, dataN] = Hooks.NotificationODataAll();

  useLayoutEffect(() => {
    getN();
  }, []);

  return (
    <Main headerProps={{ title: "Bildirimler" }}>
      <Accordion allowToggle>
        {statN !== "fulfilled" && <Text>Yükleniyor...</Text>}
        {statN === "fulfilled" &&
          (dataN as unknown as any[])?.map((item: any) => (
            <AccordionItem key={item.Id} background="white">
              <AccordionButton outlineColor="transparent">
                <HStack flex="1" textAlign="left">
                  <Box minW="90px" mr="5" fontSize="sm">
                    {moment(item.createDate).format("DD.MM.YYYY")}
                  </Box>
                  <Text>{item.title}</Text>
                </HStack>

                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box px="10" fontSize="xs">
                  <div
                    className="notification-detail"
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </Main>
  );
};

export default List;
