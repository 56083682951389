import React, { FC } from "react";
import {
  Box,
  Text,
  Image,
  useDisclosure,
  Collapse,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import moment from "moment";
import { getApiUrl } from "services/urls";
import { useGetUserOnStore } from "hooks/find-users-on-store";

type DailyNoteItemProps = {
  item: any;
  index: number;
  forceShowImage?: boolean;
};

const DailyNoteItem: FC<DailyNoteItemProps> = ({
  item,
  index,
  forceShowImage,
}) => {
  const { getUser } = useGetUserOnStore();
  const disc = useDisclosure({ defaultIsOpen: forceShowImage });
  const getUserFullname = (user?: IUser | undefined | null) => {
    if (!user) return "";

    return `${user.name} ${user.surname}`;
  };
  return (
    <Box my="2">
      <Card bgColor="white">
        <Text fontSize="xs" color="gray.500">
          {getUserFullname(getUser(item.userID))} (
          {moment(item.createDate).format("DD.MM.YYYY HH:mm")}):
        </Text>
        {!forceShowImage && (
          <Button
            onClick={disc.onToggle}
            variant="solid"
            colorScheme="blue"
            my="3"
            size="xs"
          >
            Detay göster
          </Button>
        )}
        <Collapse in={disc.isOpen}>
          <Text whiteSpace="pre-line" fontSize="sm">
            {item.body}
          </Text>

          {item.images && item.images?.length > 0 && (
            <Box>
              {disc.isOpen && (
                <SimpleGrid columns={2}>
                  {item.images.map((image: any) => (
                    <>
                      {image.imageUrl && (
                        <Box style={{ marginTop: 20 }}>
                          <Image
                            objectFit="contain"
                            src={
                              getApiUrl() +
                              "/" +
                              image.imageUrl.split(/\\/g).join("/")
                            }
                            style={{ height: 240, width: "auto" }}
                          />
                        </Box>
                      )}
                    </>
                  ))}
                </SimpleGrid>
              )}
            </Box>
          )}
        </Collapse>
      </Card>
    </Box>
  );
};

export default DailyNoteItem;
