import React, { FC, useState } from "react";
import {Box, Text} from '@chakra-ui/react'
import Main from "components/templates/Main";
import DailyNoteForm from "features/daily-note/daily-note-form/DailyNoteForm";
import DailyNoteList from "features/daily-note/daily-note-list/DailyNoteList";
import moment from "moment";

type NotesProps = {

}

const Notes: FC<NotesProps> = (props) => {
  return (
    <Main headerProps={{title: "Günlük Notlar"}}>
      <DailyNoteList  />
    </Main>
  )
}

export default Notes;