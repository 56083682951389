import React, { FC, useState } from "react";
import { Box, Button, Text, Flex, useToast, Icon } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Form, InputElement } from "components/atoms/HookForm";
import { Hooks } from "services";
import { ChangePasswordForm } from "models/forms";
import { useAuth } from "hooks/redux-hooks";
import { useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface ChangePasswordPageProps {}

interface showInputType {
  newPassword: boolean;
  newPasswordConfirm: boolean;
  currentPassword: boolean;
}

const ChangePasswordPage: FC<ChangePasswordPageProps> = (props) => {
  const [updatePass, statusPass] = Hooks.UpdateUserPassword();
  const toast = useToast();
  const history = useHistory();
  const { user } = useAuth();

  const [showInput, setShowInput] = useState<showInputType>({
    newPassword: false,
    newPasswordConfirm: false,
    currentPassword: false,
  });

  const handleTogglePassword = (inputName: keyof showInputType) => {
    setShowInput((prevState) => ({
      ...prevState,
      [inputName]: !prevState[inputName],
    }));
  };

  async function onValid(values: ChangePasswordForm) {
    if (values["new-password"] !== values["new-password-confirm"]) {
      return toast({ status: "error", title: "Şifre tekrarı hatalı!" });
    }
    const passwordChangeRequest = {
      OldPassword: values["current-password"],
      newPassword: values["new-password"],
    };
    const _data = (await updatePass(passwordChangeRequest)) as unknown as any;

    if (_data?.data?.success) {
      toast({ title: "Şifre başarıyla değiştirildi!", status: "success" });
      history.push("/");
    } else {
      toast({
        title: "Şifre değiştirilemedi!",
        description: _data.data.message,
        status: "error",
      });
    }
  }

  return (
    <Main headerProps={{ title: "Şifre Değiştir" }}>
      <Card>
        <Form onValid={onValid}>
          <Box position="relative">
            <InputElement
              name="current-password"
              label="Mevcut Şifre"
              type={showInput.currentPassword ? "text" : "password"}
              rules={{
                required: {
                  value: true,
                  message: "Bu alanın doldurulması zorunludur!",
                },
              }}
            />
            <Box
              onClick={() => handleTogglePassword("currentPassword")}
              cursor="pointer"
              position="absolute"
              right="1%"
              top="55%"
              zIndex={1}
            >
              <Icon as={showInput.currentPassword ? FaEyeSlash : FaEye} />
            </Box>
          </Box>
          <Box position="relative">
            <InputElement
              name="new-password"
              label="Yeni Şifre"
              type={showInput.newPassword ? "text" : "password"}
              containerProps={{ mt: "2" }}
              rules={{
                required: {
                  value: true,
                  message: "Bu alanın doldurulması zorunludur!",
                },
              }}
            />
            <Box
              onClick={() => handleTogglePassword("newPassword")}
              cursor="pointer"
              position="absolute"
              right="1%"
              top="55%"
              zIndex={1}
            >
              <Icon as={showInput.newPassword ? FaEyeSlash : FaEye} />
            </Box>
          </Box>
          <Box position="relative">
            <InputElement
              name="new-password-confirm"
              label="Yeni Şifre Tekrar"
              type={showInput.newPasswordConfirm ? "text" : "password"}
              containerProps={{ mt: "2" }}
              rules={{
                required: {
                  value: true,
                  message: "Bu alanın doldurulması zorunludur!",
                },
              }}
            />
            <Box
              onClick={() => handleTogglePassword("newPasswordConfirm")}
              cursor="pointer"
              position="absolute"
              right="1%"
              top="55%"
              zIndex={1}
            >
              <Icon as={showInput.newPasswordConfirm ? FaEyeSlash : FaEye} />
            </Box>
          </Box>
          <Flex justifyContent="flex-end">
            <Button mt="3" type="submit" colorScheme="teal">
              Şifreyi Değiştir
            </Button>
          </Flex>
        </Form>
      </Card>
    </Main>
  );
};

ChangePasswordPage.defaultProps = {};

export default ChangePasswordPage;
