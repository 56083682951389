import React, { Fragment } from "react";
import {
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { IoPerson } from "react-icons/io5";
import { Authenticator } from "../../helper/authenticator";
import { useHistory } from "react-router-dom";
import { Hooks } from "services";

function UserMenu() {
  const { isOpen, onToggle } = useDisclosure();
  const history = useHistory();
  const [logout] = Hooks.Logout();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={IoPerson} />}
        borderRadius="full"
      />
      <MenuList>
        <MenuItem onClick={() => history.push("/my-account-edit")}>
          Bilgileri Güncelle
        </MenuItem>
        <MenuItem onClick={() => history.push("/change-password")}>
          Şifre Değiştir
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            Authenticator.Logout();
          }}
        >
          Çıkış Yap
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default UserMenu;
